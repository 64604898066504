import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { useLocation } from 'react-router-dom'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import TopNStudents from '../../components/results/TopNStudents'
import ResultsTitle from '../../components/results/ResultsTitle'
import { useReactToPrint } from 'react-to-print'
import { saveAs } from 'file-saver'

function TeachersSubjectWise() {
    const auth = useAuth()
    const location = useLocation()
    const exam = location.state.exam
    const form = location.state.form

    const contentRef = useRef()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const headers = [
        {
            name: "TEACHER'S NAME",
            key: "name",
            align: "start"
        },
        {
            name: "SEX",
            key: "sex",
            align: "center"
        },
        {
            name: "SCHOOL",
            key: "school",
            align: "start"
        },
        {
            name: "GPA",
            key: "gpa",
            align: "center"
        },
        {
            name: "AVERAGE",
            key: "average",
            align: "center"
        },
        {
            name: "GRADE",
            key: "grade",
            align: "center"
        },
        {
            name: "POSITION",
            key: "position",
            align: "center"
        }
    ]

    const [results, setResults] = useState({})

    const handlePrint = useReactToPrint({
        documentTitle: `MSSIS-${exam.name}-Teachers' Ranking Subjectwise`,
        removeAfterPrint: true,
    })

    const handleExport = async () => {
        setIsLoading(true)
        setMessage("")

        try {
            let url = `${BASE_API_URL}/results/export/${exam.id}/${form.id ? form.id : form}/subjectwise`
            if (auth.user.role === "DSEO") {
                url += `?school=${exam.participants[0].id}`
            }

            if (exam.type === "External") {
                url = `${BASE_API_URL}/results/external/export/${exam.id}/${form.id ? form.id : form}/subjectwise-teachers`
            }

            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant("warning")
                return
            }
            
            let suff = "MSSIS"
			if (auth.user.school) {
				suff = auth.user.school.name
			}

			const filename = `${suff} ${exam?.name} Teachers' Ranking Subject Wise ${new Date().toISOString().split('T')[0]}.pdf`

			const blob = await response.blob()
			saveAs(blob, filename)
        }
        catch(error) {
            setMessage("Failed to export results to PDF. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchResults = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            let url = `${BASE_API_URL}/results/analysis/${exam.id}/${form.id ? form.id : form}/subjectwise`
            if (auth.user.role === "DSEO") {
                url += `?school=${exam.participants[0].id}`
            }

            if (exam.type === "External") {
                url = `${BASE_API_URL}/results/external/analysis/${exam.id}/${form.id ? form.id : form}/subjectwise-teachers`
            }

            const response = await fetch(url, {
                headers: {
                    'x-access-token': auth.token
                }
            })
            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setResults(data)
            }
        }
        catch (error) {
            setMessage('Failed to fetch exam results. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchResults()
    }, [])

    return (
        <div className='pt-2'>
            <div className="mb-3 text-end">
                <button
                    className='btn btn-sm btn-primary rounded-0'
                    disabled={isLoading}
                    onClick={() => {
                        // handlePrint(null, () => contentRef.current)
                        handleExport()
                    }}
                >
                    <span className="me-2">Export to PDF</span>
                    <i className='bi bi-file-earmark-pdf'></i>
                </button>
            </div>

            {
                isLoading ?
                    (
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                    )
                    :
                    (
                        <div className="body shadow p-2 mb-3" id="printContent" ref={contentRef}>
                            {
                                message &&
                                <div className={`alert alert-${variant} p-2`} role="alert">
                                    {message}
                                </div>
                            }

                            <div className="mb-3">
                                <ResultsTitle
                                    exam={exam}
                                    form={form}
                                    school={results.school}
                                    title={"TEACHERS' RANKING - SUBJECT WISE"}
                                />
                            </div>

                            {
                                results.ranks && Object.keys(results.ranks).map((rank, index) => (
                                    <div className="mb-3" key={index}>
                                        <TopNStudents
                                            title={`${index + 1}. ${results.ranks[rank]["name"].toUpperCase()}`}
                                            headers={headers}
                                            students={results.ranks[rank]["teachers"]}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    )
            }
        </div>
    )
}

export default TeachersSubjectWise
