import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../utils/constants'
import { Link } from 'react-router-dom'
import RoleOffice from '../../components/offices/RoleOffice'
import { saveAs } from 'file-saver'

function Students() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')

    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [totalStudents, setTotalStudents] = useState(0)

    const [searchTerm, setSearchTerm] = useState('')
    const [students, setStudents] = useState([])
    const [fetchedStudents, setFetchedStudents] = useState([])

    const [schools, setSchools] = useState([])
    const [forms, setForms] = useState([])
    const [filterSchool, setFilterSchool] = useState('')
    const [filterForm, setFilterForm] = useState('')
    const [filterSex, setFilterSex] = useState('')

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => `${row.firstname} ${row.middlename} ${row.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: 'PREMS No',
            selector: row => row.prems_number,
            sortable: true,
        },
        {
            name: 'Admission No',
            selector: row => row.admission_number,
            sortable: true,
        },
        {
            name: 'Sex',
            selector: row => row.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true,
        },
        {
            name: 'Class/Form',
            selector: row => row.form,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row =>
                <div className='d-flex'>
                    <Link
                        to={`/students/${row.id}`}
                        state={{ staff: row }}
                        className='btn btn-sm btn-primary rounded-0 me-2'>
                        <i className='bi bi-pencil-square fs-6 mx-1'></i>
                    </Link>
                    <Link
                        to={`/students/${row.id}`}
                        state={{ staff: row }}
                        className='btn btn-sm btn-primary rounded-0'>
                        <i className='bi bi-eye-fill fs-6 mx-1'></i>
                    </Link>
                </div>,
            sortable: false,
        }
    ]

    const handleSearch = async (e) => {
        e.preventDefault()

        if (searchTerm.length < 1) {
            return
        }

        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/students/search?q=${searchTerm}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setStudents(data.students)
                setCurrentPage(data.page)
                setPerPage(data.per_page)
                setTotalStudents(data.total_students)
            }
        }
        catch (error) {
            setMessage('Failed to fetch students. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchStudents = async (cp = 1, pp = 10) => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/students?page=${cp}&per_page=${pp}&school=${filterSchool}&form=${filterForm}&sex=${filterSex}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setStudents(data.students)
                setFetchedStudents(data.students)
                setCurrentPage(data.page)
                setPerPage(data.per_page)
                setTotalStudents(data.total_students)
            }
        }
        catch (error) {
            setMessage('Failed to fetch students. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchSchools = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/schools`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setSchools(data.schools)
            }
        }
        catch (error) {
            setMessage('Failed to fetch schools. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchForms = async () => {
        setIsLoading(true)
        setMessage('')

        setForms([
            {
                "date_created": "25/04/2024",
                "id": "5bd44adf4121456c901b36effa59b474",
                "level": "O Level",
                "name": "Form I",
                "value": "1"
            },
            {
                "date_created": "25/04/2024",
                "id": "29fba2e24f4344c9ada15129f0bd517b",
                "level": "O Level",
                "name": "Form II",
                "value": "2"
            },
            {
                "date_created": "25/04/2024",
                "id": "bded3392b64c4a68b217a46524503f53",
                "level": "O Level",
                "name": "Form III",
                "value": "3"
            },
            {
                "date_created": "25/04/2024",
                "id": "22ba77b749d24a66afcf999f30e88065",
                "level": "O Level",
                "name": "Form IV",
                "value": "4"
            }
        ])

        setIsLoading(false)

        // reduce number of requests made, in the future if it seems to be neccessary
        // to fetch forms from the server uncomment the code below and remove the code above
        // try {
        //     const response = await fetch(`${BASE_API_URL}/classes/bare`, {
        //         headers: {
        //             'x-access-token': auth.token
        //         }
        //     })

        //     const data = await response.json()
        //     if (!response.ok) {
        //         setMessage(data.message)
        //         setVariant('warning')
        //     }
        //     else {
        //         setForms(data.classes)
        //     }
        // }
        // catch (error) {
        //     setMessage('Failed to fetch forms. Please try again.')
        //     setVariant('danger')
        // }
        // finally {
        //     setIsLoading(false)
        // }
    }

    const handleExport = async (exportType) => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/students/export/${exportType}?school=${filterSchool}&form=${filterForm}&sex=${filterSex}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            if (!response.ok) {
                const data = await response.json()
                setMessage(data.message)
                setVariant('warning')
                return
            }

            let suff = "MSSIS"
            if (auth.user.school) {
                suff = auth.user.school.name
            }

            let extension = "pdf"
			if (exportType === "excel") {
				extension = "xlsx"
			}

            const filename = `${suff} Students Export ${new Date().toISOString().split('T')[0]}.${extension}`

            const blob = await response.blob()
            saveAs(blob, filename)
        }
        catch (error) {
            setMessage('Failed to export students. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchForms()
        if (auth.user.role === 'DSEO') {
            fetchSchools()
        }
    }, [])

    useEffect(() => {
        if (searchTerm.length < 1) {
            fetchStudents(currentPage, perPage)
        }
    }, [currentPage, perPage, searchTerm])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted'>Students</p>
                <RoleOffice roles={["Headmaster", "Academic Master"]} element={
                    <Link to='/students/new' className='btn btn-primary btn-sm fw-bold rounded-0'>
                        <span className="me-2">Register</span>
                        <i className='bi bi-person-plus-fill'></i>
                    </Link>
                } />
            </div>

            <form onSubmit={handleSearch} className="body shadow p-2 mb-3 d-flex">
                <input
                    type="text"
                    className="form-control me-1 rounded-0"
                    placeholder="Search students..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
                <button type='submit' className="btn btn-sm btn-primary px-3 fw-bold rounded-0">
                    Search
                </button>
            </form>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3 shadow p-2">
                <p className='text-muted fw-bold'>Filter Students</p>
                <form onSubmit={e => {
                    e.preventDefault()
                    fetchStudents(currentPage, perPage)
                }}>
                    <div className="row mx-0 ">
                        <RoleOffice roles={["DSEO"]} element={
                            <div className="col-12 col-lg-3 mb-2">
                                <label htmlFor='school' className='form-label'>School</label>
                                <select
                                    id='school'
                                    className='form-select rounded-0'
                                    value={filterSchool}
                                    onChange={e => setFilterSchool(e.target.value)}
                                >
                                    <option value='all'>All Schools</option>
                                    {
                                        schools.map(school =>
                                            <option key={school.id} value={school.id}>{school.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        } />
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='form' className='form-label'>Form</label>
                            <select
                                id='form'
                                className='form-select rounded-0'
                                value={filterForm}
                                onChange={e => setFilterForm(e.target.value)}
                            >
                                <option value='all'>All Forms</option>
                                {
                                    forms.map(form =>
                                        <option key={form.id} value={form.id}>{form.name}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mb-2">
                            <label htmlFor='sex' className='form-label'>Sex</label>
                            <select
                                id='sex'
                                className='form-select rounded-0'
                                value={filterSex}
                                onChange={e => setFilterSex(e.target.value)}
                            >
                                <option value='all'>All Sex</option>
                                <option value="Female">Female</option>
                                <option value="Male">Male</option>
                            </select>
                        </div>
                        <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end">
                            <button type='submit' className='btn btn-primary rounded-0 mt-4'>
                                <span className="me-2">Filter</span>
                                <i className='bi bi-funnel-fill'></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div className="my-3">
                {

                    <DataTable
                        title={
                            <div className='d-flex align-items-center justify-content-between'>
                                <span>{totalStudents} Students</span>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <button className='btn btn-sm btn-primary rounded-0 me-2' onClick={e => handleExport("pdf")}>
                                            <span className="me-2">Export to PDF</span>
                                            <i className='bi bi-file-earmark-pdf'></i>
                                        </button>
                                        <button className='btn btn-sm btn-primary rounded-0' onClick={e => handleExport("excel")}>
                                            <span className="me-2">Export to Excel</span>
                                            <i className='bi bi-file-earmark-spreadsheet'></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                        columns={columns}
                        data={students}
                        progressPending={isLoading}
                        progressComponent={
                            <div className="text-center">
                                Loading... <Loading />
                            </div>
                        }
                        pagination
                        paginationServer
                        paginationTotalRows={totalStudents}
                        onChangePage={p => setCurrentPage(p)}
                        onChangeRowsPerPage={pp => setPerPage(pp)}
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10, 30, 50, 150]}
                        noDataComponent={"No students found."}
                    />
                }
            </div>
        </div>
    )
}

export default Students
