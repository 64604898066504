import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'
import { convertDateFormat } from '../../utils/date_conversions'

function NewStudents() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [phoneError, setPhoneError] = useState('')
    const [errorsFile, setErrorsFile] = useState('')

    const [firstname, setFirstname] = useState('')
    const [middlename, setMiddlename] = useState('')
    const [lastname, setLastname] = useState('')
    const [sex, setSex] = useState('')
    const [premsNumber, setPremsNumber] = useState('')
    const [admissionNumber, setAdmissionNumber] = useState('')
    const [dob, setDob] = useState('')
    const [nationality, setNationality] = useState('')
    const [tribe, setTribe] = useState('')
    const [form, setForm] = useState('') // Form is the class
    const [stream, setStream] = useState('')

    const [parentName, setParentName] = useState('')
    const [parentRelationship, setParentRelationship] = useState('')
    const [parentResidence, setParentResidence] = useState('')
    const [parentPhone, setParentPhone] = useState('')
    const [parentOccupation, setParentOccupation] = useState('')

    const [classes, setClasses] = useState([])
    const [file, setFile] = useState(null)

    const fetchClases = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/classes`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setClasses(data.classes)
            }
        }
        catch (error) {
            setMessage('An error occurred while fetching classes. Please refresh the page.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setErrorsFile('')

        try {
            const response = await fetch(`${BASE_API_URL}/students`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    firstname,
                    middlename,
                    lastname,
                    sex,
                    premsNumber,
                    admissionNumber,
                    dob,
                    nationality,
                    tribe,
                    form,
                    stream,
                    parentName,
                    parentRelationship,
                    parentResidence,
                    parentPhone,
                    parentOccupation
                })
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
                // setFirstname('')
                // setMiddlename('')
                // setLastname('')
                // setSex('')
                // setPremsNumber('')
                // setAdmissionNumber('')
                // setDob('')
                // setNationality('')
                // setTribe('')
                // setForm('')
                // setStream('')
                // setParentName('')
                // setParentRelationship('')
                // setParentResidence('')
                // setParentPhone('')
                // setParentOccupation('')
            }
        }
        catch (error) {
            setMessage('An error occurred while registering student. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleUpload = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('Please wait while data is being processed.')
        setVariant('info')
        setErrorsFile('')

        const formData = new FormData()
        formData.append('file', file)

        try {
            const response = await fetch(`${BASE_API_URL}/students/bulk`, {
                method: 'POST',
                headers: {
                    'x-access-token': auth.token
                },
                body: formData
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
                if (data.errors) {
                    setErrorsFile(`${BASE_API_URL}/errors/file/${data.file.split('/').pop()}`)
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage('An error occurred while uploading file. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchClases()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted mb-3'>New Student</p>
            <div className='shadow p-2 mb-4'>
                {
                    message &&
                    <div className={`alert alert-${variant} p-2`} role="alert">
                        {message}
                    </div>
                }

                <form onSubmit={handleSubmit}>
                    <p className='h4 mb-3 text-muted'>Basic Info</p>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='firstname'>
                                    First Name <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='firstname'
                                    required
                                    disabled={isLoading}
                                    value={firstname}
                                    onChange={e => setFirstname(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label htmlFor="middlename">Middle Name</label>
                                <input
                                    type="text"
                                    className="form-control rounded-0"
                                    id="middlename"
                                    disabled={isLoading}
                                    value={middlename}
                                    onChange={e => setMiddlename(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='lastname'>
                                    Last Name <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='lastname'
                                    required
                                    disabled={isLoading}
                                    value={lastname}
                                    onChange={e => setLastname(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label htmlFor="sex">
                                    Sex <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <select
                                    className="form-select rounded-0"
                                    id='sex'
                                    required
                                    disabled={isLoading}
                                    value={sex}
                                    onChange={e => setSex(e.target.value)}
                                >
                                    <option value="">Select Sex</option>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <div className="form-group">
                                <label htmlFor="dob">
                                    Date of Birth <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type="date"
                                    className="form-control rounded-0"
                                    id="dob"
                                    required
                                    disabled={isLoading}
                                    value={convertDateFormat(dob)}
                                    onChange={e => setDob(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='nationality'>
                                    Nationality <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='nationality'
                                    required
                                    disabled={isLoading}
                                    value={nationality}
                                    onChange={e => setNationality(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='tribe'>
                                    Tribe <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='tribe'
                                    required
                                    disabled={isLoading}
                                    value={tribe}
                                    onChange={e => setTribe(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <p className='h4 mb-3 text-muted'>Academic Info</p>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='prems_number'>
                                    PREMS Number <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='prems_number'
                                    required
                                    disabled={isLoading}
                                    value={premsNumber}
                                    onChange={e => setPremsNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='admission_number'>
                                    Admission Number <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='admission_number'
                                    required
                                    disabled={isLoading}
                                    value={admissionNumber}
                                    onChange={e => setAdmissionNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='form'>
                                    Class/Form <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <select
                                    className="form-select rounded-0"
                                    id='form'
                                    required
                                    disabled={isLoading}
                                    value={form}
                                    onChange={e => setForm(e.target.value)}
                                >
                                    <option value="">Select Student's Class</option>
                                    {
                                        classes.map((cls, index) => (
                                            <option key={index} value={cls.id}>{cls.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='stream'>
                                    Stream <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <select
                                    className="form-select rounded-0"
                                    id='stream'
                                    required
                                    disabled={isLoading}
                                    value={stream}
                                    onChange={e => setStream(e.target.value)}
                                >
                                    <option value="">Select Student's Class Stream</option>
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="C">C</option>
                                    <option value="D">D</option>
                                    <option value="E">E</option>
                                    <option value="F">F</option>
                                    <option value="G">G</option>
                                    <option value="H">H</option>
                                    <option value="I">I</option>
                                    <option value="J">J</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* parent details */}
                    <p className='h4 mb-3 text-muted'>Parent Info</p>
                    <div className='row mb-3'>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='parent_name'>
                                    Full Name <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='parent_name'
                                    required
                                    disabled={isLoading}
                                    value={parentName}
                                    onChange={e => setParentName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='relationship'>
                                    Relationship <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <select
                                    className='form-select rounded-0'
                                    id='relationship'
                                    required
                                    disabled={isLoading}
                                    value={parentRelationship}
                                    onChange={e => setParentRelationship(e.target.value)}
                                >
                                    <option value=''>Select Relationship</option>
                                    <option value='Father'>Father</option>
                                    <option value='Mother'>Mother</option>
                                    <option value='Guardian'>Guardian</option>
                                    <option value='Other'>Other</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='phone'>
                                    Phone (255...) <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='phone'
                                    required
                                    value={parentPhone}
                                    onChange={(e) => {
                                        // test phonenumber with regex: 255(6|7)[0-9]{8}
                                        if (!(/^255(6|7)[0-9]{8}$/.test(e.target.value))) {
                                            setPhoneError(true)
                                        }
                                        else {
                                            setPhoneError(false)
                                        }
                                        setParentPhone(e.target.value)
                                    }
                                    }
                                />
                                {
                                    phoneError &&
                                    <small className="text-danger">Enter a valid phone number</small>
                                }
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='parentresidence'>
                                    Residence <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='parentresidence'
                                    required
                                    disabled={isLoading}
                                    value={parentResidence}
                                    onChange={e => setParentResidence(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 mb-3'>
                            <div className='form-group'>
                                <label htmlFor='parent_occupation'>
                                    Occupation <strong className='text-danger ms-1'>*</strong>
                                </label>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    id='parent_occupation'
                                    required
                                    disabled={isLoading}
                                    value={parentOccupation}
                                    onChange={e => setParentOccupation(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <span>
                            <strong className='text-danger ms-1'>*</strong> indicates required fields
                        </span>
                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading || phoneError}>
                            {
                                isLoading ?
                                    <>Registering... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Register
                                        </span>
                                        <i className='bi bi-floppy2-fill'></i>
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>

            <div className='shadow p-2 mb-4'>
                {
                    message &&
                    <>
                        <div className={`alert alert-${variant} p-2`} role="alert">
                            {message}
                        </div>
                        <div className="my-2">
                            {
                                errorsFile &&
                                <div className="my-3">
                                    <p className='text-danger'>
                                        The errors file appears here only once. If you do not download it now, 
                                        you will not find it again!<br/>
                                        Please download the file now and fix the errors, then re-upload it.
                                    </p>
                                    <a href={errorsFile} target='blank' download={true} className='btn btn-primary rounded-0'>
                                        <span className='me-2'>Download Errors File</span>
                                        <i className='bi bi-download'></i>
                                    </a>
                                </div>
                            }
                        </div>
                    </>
                }

                <div className="d-flex align-items-center justify-content-between">
                    <p className='h4 text-muted'>Bulk Registration</p>
                    <a href={`${BASE_API_URL}/static/templates/mssis-template-student-new.xlsx`} target='blank' download={true} className='btn btn-primary rounded-0 mb-3'>
                        <span className='me-2'>Download Template</span>
                        <i className='bi bi-download'></i>
                    </a>
                </div>
                <p className='mb-3'>
                    You can register multiple students at once by uploading an excel file.
                    <br />
                    First, download the template file, fill in the details and upload it here.
                </p>
                <div className='text-end mb-3'>
                    <form encType='multipart/form-data' onSubmit={handleUpload}>
                        <input
                            type='file'
                            className='form-control mb-3 rounded-0'
                            accept='.xlsx, .xls'
                            required
                            disabled={isLoading}
                            onChange={e => setFile(e.target.files[0])}
                        />
                        <button className='btn btn-primary rounded-0 px-4' disabled={isLoading}>
                            {
                                isLoading ?
                                    <>Uploading... <Loading /></> :
                                    <>
                                        <span className="me-2">
                                            Upload
                                        </span>
                                        <i className='bi bi-upload'></i>
                                    </>
                            }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewStudents
