import React, { useEffect, useState } from 'react'
import Loading from '../../components/loading/Loading'
import { useAuth } from '../../context/AuthContext'
import DataTable from 'react-data-table-component'
import { BASE_API_URL } from '../../utils/constants'
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog'

function Designation() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("")
    const [isEditing, setIsEditing] = useState("")
    const [showDialog, setShowDialog] = useState(false)

    const [designations, setDesignations] = useState([])
    const [staffs, setStaffs] = useState([])
    const [academicMaster, setAcademicMaster] = useState([])

    const [toDelete, setToDelete] = useState({})

    const columns = [
        {
            name: 'S/N',
            cell: (row, index) => index + 1,
            sortable: false,
            grow: 0,
        },
        {
            name: 'Designation',
            selector: row => row.designation,
            sortable: true,
        },
        {
            name: 'Name of Staff',
            selector: row => `${row.user.firstname} ${row.user.lastname}`,
            sortable: true,
        },
        {
            name: 'Date of Designation',
            selector: row => row.designation_date,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <button
                    className='btn btn-sm btn-danger rounded-0'
                    onClick={() => {
                        setToDelete(row)
                        setShowDialog(true)
                    }}
                >
                    <span className="me-2">Remove</span>
                    <i className='bi bi-trash'></i>
                </button>
            ),
        }
    ]

    const fetchDesignations = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/designations`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setDesignations(data.designations)
            }
        } catch (error) {
            setMessage('Failed to fetch staffs. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    const fetchStaffs = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/employees`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setStaffs(data.employees)
            }
        } catch (error) {
            setMessage('Failed to fetch staffs. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/designations`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    academic_master: academicMaster
                })
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
                fetchDesignations()
            }
        } catch (error) {
            setMessage('Failed to designate staff. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }

    }

    const handleDelete = async (userId, designation) => {
        setIsLoading(true)
        setMessage('')
        setShowDialog(false)

        try {
            const response = await fetch(`${BASE_API_URL}/designations`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    user: userId,
                    role: designation
                })
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setMessage(data.message)
                setVariant('success')
                fetchDesignations()
            }
        } catch (error) {
            setMessage('Failed to delete designation. Please try again.')
            setVariant('danger')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchDesignations()
        fetchStaffs()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <p className='h3 fw-bold text-muted'>Staff Designation</p>
                </div>
            </div>

            <ConfirmationDialog
                open={showDialog}
                onClose={() => {
                    setShowDialog(false)
                    setIsLoading(false)
                }}
                onConfirm={() => {
                    handleDelete(toDelete.user.id, toDelete.actual_designation)
                }}
                isLoading={isLoading}
                title='Remove Designation'
                message={`Are you sure you want to remove the user from designation?`}
            />

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3 shadow p-2">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <>
                            <div className='mx-0 mb-4'>
                                <p className='h4 mb-3 text-muted'>Current Designations</p>
                                <DataTable
                                    columns={columns}
                                    data={designations}
                                    responsive
                                    highlightOnHover
                                    noDataComponent={"No designations found."}
                                />
                            </div>

                            <div className='mx-0 mb-4'>
                                <p className='h4 mb-3 text-muted'>Designate Staff</p>
                                <form onSubmit={handleSubmit}>
                                    <div className='row mb-3'>
                                        <div className='col-lg-4 mb-3'>
                                            <div className='form-group'>
                                                <label htmlFor='academicMaster' className='form-label'>
                                                    Academic Master/Mistress
                                                </label>
                                                <select
                                                    className="form-select rounded-0"
                                                    id='academicMaster'
                                                    required
                                                    disabled={isLoading}
                                                    value={academicMaster}
                                                    onChange={e => setAcademicMaster(e.target.value)}
                                                >
                                                    <option value="">Select Staff</option>
                                                    {
                                                        staffs.map(staff => (
                                                            <option key={staff.id} value={staff.id}>
                                                                {`${staff.firstname} ${staff.lastname}`}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        message &&
                                        <div className={`alert alert-${variant} p-2`} role="alert">
                                            {message}
                                        </div>
                                    }

                                    <div className="text-end mb-3">
                                        <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                                            {
                                                isLoading ?
                                                    <>Designating... <Loading /></> :
                                                    <>
                                                        <span className="me-2">
                                                            Designate
                                                        </span>
                                                        <i className='bi bi-floppy2-fill'></i>
                                                    </>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default Designation
