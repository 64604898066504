import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import RoleOffice from '../../components/offices/RoleOffice'
import Loading from '../../components/loading/Loading'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import DataTable from 'react-data-table-component'
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog'

function ViewSubject() {
    const auth = useAuth()
    const user = auth.user
    const params = useParams()
    const subjectId = params.id

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('success')
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const [studentCurrentPage, setStudentCurrentPage] = useState(1)
    const [studentPerPage, setStudentPerPage] = useState(10)
    const [showDialog, setShowDialog] = useState(false)
    const [totalStudents, setTotalStudents] = useState(0)
    const [errors, setErrors] = useState([])

    const [subject, setSubject] = useState({})
    const [enrolledStudents, setEnrolledStudents] = useState([])
    const [toDelete, setToDelete] = useState({})
    const [selectedStudents, setSelectedStudents] = useState([])
    const [filterForm, setFilterForm] = useState('')
    const [filterSex, setFilterSex] = useState('')

    const subject_teachers_columns = [
        {
            name: 'S/N',
            selector: (row, index) => perPage * (currentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'School',
            selector: row => `${row.school} Secondary School`,
            sortable: true
        },
        {
            name: 'Name',
            selector: row => `${row.teacher.firstname} ${row.teacher.lastname}`,
            sortable: true
        },
        {
            name: 'Form',
            selector: row => row.form,
            sortable: true
        },
        {
            name: 'Date Started',
            selector: row => row.date_started,
            sortable: true
        },
        {
            name: 'Date Ended',
            selector: row => row.date_ended,
            sortable: true
        }
    ]

    const enrolled_students_columns = [
        {
            name: 'S/N',
            selector: (row, index) => studentPerPage * (studentCurrentPage - 1) + (index + 1),
            grow: 0,
        },
        {
            name: 'Name',
            selector: row => `${row.firstname} ${row.middlename} ${row.lastname}`,
            sortable: true,
            grow: 2,
        },
        {
            name: 'PREMS No',
            selector: row => row.prems_number,
            sortable: true,
        },
        {
            name: 'Admission No',
            selector: row => row.admission_number,
            sortable: true,
        },
        {
            name: 'Sex',
            selector: row => row.sex,
            sortable: true,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school,
            sortable: true,
        },
        {
            name: 'Class/Form',
            selector: row => row.form,
            sortable: true,
        },
        {
            name:
                <div>
                    <input
                        type='checkbox'
                        className='me-1'
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelectedStudents(enrolledStudents.map(s => s.id))
                            } else {
                                setSelectedStudents([])
                            }
                        }}
                    />
                    <span>({selectedStudents.length})</span>
                </div>,
            cell: row =>
                <div className='d-flex flex-wrap align-items-center'>
                    <button
                        className='btn btn-sm btn-danger rounded-0 me-1 mb-1'
                        onClick={() => {
                            setToDelete(row)
                            setShowDialog(true)
                        }}>
                        <i className='bi bi-trash-fill fs-6 mx-1'></i>
                    </button>
                    <button
                        type='button'
                        className={`btn btn-sm rounded-0 ${selectedStudents.includes(row.id) ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={() => {
                            if (selectedStudents.includes(row.id)) {
                                setSelectedStudents(selectedStudents.filter(studentId => studentId !== row.id))
                            } else {
                                setSelectedStudents([...selectedStudents, row.id])
                            }
                        }}>
                        {selectedStudents.includes(row.id) ? 'Selected' : 'Select'}
                    </button>
                </div>,
            sortable: false,
        }
    ]

    const fetchSubject = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const response = await fetch(`${BASE_API_URL}/subjects/${subjectId}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setSubject(data.subject)
            }
        }
        catch (error) {
            setMessage('Failed to fetch subject. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const fetchEnrolledStudents = async (cp = 1, pp = 10) => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/subjects/${subjectId}/students?page=${cp}&per_page=${pp}&form=${filterForm}&sex=${filterSex}`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setEnrolledStudents(data.students)
                setTotalStudents(data.total)
                setStudentCurrentPage(data.page)
                setStudentPerPage(data.per_page)
            }
        }
        catch (error) {
            setMessage('Failed to fetch enrolled students. Please try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    const deregisterStudent = async () => {
        setIsLoading(true)
        setMessage('')
        setErrors([])

        let students = []
        if (toDelete && toDelete.id) {
            students = [toDelete.id]
        }
        else {
            students = selectedStudents
        }

        if (students.length < 1) {
            setMessage("Select at least one student to deregister")
            setVariant("warning")
            setIsLoading(false)
            return
        }

        try {
            const response = await fetch(`${BASE_API_URL}/subjects/${subjectId}/students`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify({
                    "students": students
                })
            })

            const data = await response.json()
            if (!response.ok) {
                setErrors(data.errors)
                setMessage(data.message)
                setVariant('warning')
            }
            else {
                setMessage(data.message)
                setVariant('success')
                setSelectedStudents([])
                fetchEnrolledStudents()
            }
        }
        catch (error) {
            setMessage('Failed to deregister student. Please try again.')
            setVariant('danger')
        }
        finally {
            setToDelete({})
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSubject()
        if (user.role !== "DSEO") {
            fetchEnrolledStudents()
        }
    }, [])

    useEffect(() => {
        if (user.role !== "DSEO") {
            fetchEnrolledStudents(studentCurrentPage, studentPerPage)
        }
    }, [studentCurrentPage, studentPerPage])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <p className='h3 fw-bold text-muted mb-3'>Subject Details</p>
                <RoleOffice
                    // temporarily disable editing of subjects
                    roles={[""]}
                    element={
                        <Link to={`/academics/subject/${subjectId}/edit`} className='btn btn-primary btn-sm fw-bold rounded-0'>
                            <span className="me-2">Edit</span>
                            <i className='bi bi-pencil-square'></i>
                        </Link>
                    }
                />
            </div>

            {
                <>
                    <div className='shadow p-2 mb-4'>
                        {
                            message &&
                            <div className={`alert alert-${variant} p-2`} role="alert">
                                {message}
                            </div>
                        }

                        {
                            isLoading ?
                                <div className="text-center">
                                    Loading... <Loading />
                                </div> :
                                <div className="">
                                    <div className="">
                                        <p>
                                            <span className='fw-bold'>Subject Name:</span>
                                            <br />
                                            <span className='text-muted fs-5'>
                                                {subject.name}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p>
                                            <span className='fw-bold'>Subject Code:</span>
                                            <br />
                                            <span className='text-muted fs-5'>
                                                {subject.code}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="mb-3">
                                        <p>
                                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2">
                                                <span className='fw-bold'>Subject Teachers:</span>
                                                <RoleOffice
                                                    roles={["Academic Master", "Headmaster"]}
                                                    element={
                                                        <Link
                                                            to={`/academics/subjects/${subjectId}/assign-teachers`}
                                                            state={{ subject: subject }}
                                                            className='btn btn-primary btn-sm fw-bold rounded-0 px-2'
                                                            style={{ whiteSpace: "nowrap" }}
                                                        >
                                                            <span className="me-2 mb-2">Assign Subject Teacher</span>
                                                            <i className='bi bi-person'></i>
                                                        </Link>
                                                    }
                                                />
                                            </div>
                                            <span className='text-muted fs-5'>
                                                {
                                                    subject.teachers && (
                                                        subject.teachers.length > 0 ?
                                                            user.role != "DSEO" ?
                                                                subject.teachers.map((teacher, index) => (
                                                                    <>
                                                                        <span key={index}>
                                                                            {teacher.form.name} - {teacher.firstname} {teacher.lastname}
                                                                        </span>
                                                                        <br />
                                                                    </>
                                                                )) :
                                                                (
                                                                    <DataTable
                                                                        columns={subject_teachers_columns}
                                                                        data={subject.teachers}
                                                                        pagination
                                                                        paginationPerPage={perPage}
                                                                        onChangePage={page => setCurrentPage(page)}
                                                                        onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                                                        highlightOnHover
                                                                        pointerOnHover
                                                                        noHeader
                                                                    />
                                                                ) :
                                                            (
                                                                'No teachers assigned'
                                                            )
                                                    )
                                                }
                                            </span>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p>
                                            <span className='fw-bold'>Enrolled Students:</span>
                                            <br />
                                            <span className='text-muted fs-5'>
                                                {
                                                    subject.students && subject.students.length > 0 ?
                                                        subject.students.map((student, index) => (
                                                            <>
                                                                <span key={index}>
                                                                    {student.form.name} - {student.count}
                                                                </span>
                                                                <br />
                                                            </>
                                                        ))
                                                        :
                                                        (
                                                            "No students enrolled"
                                                        )
                                                }
                                            </span>
                                        </p>
                                    </div>
                                </div>
                        }
                    </div>

                    <div className="mb-3">
                        {
                            message &&
                            <>
                                <div className={`alert alert-${variant} p-2 mb-2`} role="alert">
                                    {message}
                                </div>
                                <div>
                                    {
                                        errors.map((error, index) => (
                                            <div className="alert alert-warning p-2 mb-1" key={index}>
                                                {error}
                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                        }
                    </div>

                    <div className="my-3 shadow p-2">
                        <p className='text-muted fw-bold'>Filter Students</p>
                        <form onSubmit={e => {
                            e.preventDefault()
                            fetchEnrolledStudents(1, studentPerPage)
                        }}>
                            <div className="row mx-0 ">
                                <div className="col-12 col-lg-3 mb-2">
                                    <label htmlFor='form' className='form-label'>Form</label>
                                    <select
                                        id='form'
                                        className='form-select rounded-0'
                                        value={filterForm}
                                        onChange={e => setFilterForm(e.target.value)}
                                    >
                                        <option value='all'>All Forms</option>
                                        <option value='Form I'>From I</option>
                                        <option value='Form II'>From II</option>
                                        <option value='Form III'>From III</option>
                                        <option value='Form IV'>From IV</option>
                                    </select>
                                </div>
                                <div className="col-12 col-lg-3 mb-2">
                                    <label htmlFor='sex' className='form-label'>Sex</label>
                                    <select
                                        id='sex'
                                        className='form-select rounded-0'
                                        value={filterSex}
                                        onChange={e => setFilterSex(e.target.value)}
                                    >
                                        <option value='all'>All Sex</option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                    </select>
                                </div>
                                <div className="col-12 col-lg-3 mt-2 mb-2 me-0 ms-auto text-end">
                                    <button type='submit' className='btn btn-primary rounded-0 mt-4'>
                                        <span className="me-2">Filter</span>
                                        <i className='bi bi-funnel-fill'></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <RoleOffice
                        roles={["Academic Master", "Headmaster"]}
                        element={
                            <div className="shadow p-2 mb-4">
                                <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                    <p className='h5 fw-bold text-muted'>Enrolled Students</p>
                                    <Link
                                        to={`/academics/subjects/${subjectId}/enrol`}
                                        className='btn btn-primary btn-sm fw-bold rounded-0 px-2'
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        <span className="me-2 mb-2">Enrol Students</span>
                                        <i className='bi bi-person-fill-add'></i>
                                    </Link>
                                </div>
                                <ConfirmationDialog
                                    open={showDialog}
                                    onClose={() => {
                                        setToDelete({})
                                        setShowDialog(false)
                                        setIsLoading(false)
                                    }}
                                    onConfirm={() => {
                                        deregisterStudent()
                                        setShowDialog(false)
                                    }}
                                    isLoading={isLoading}
                                    title="Deregister Student"
                                    message={
                                        toDelete && toDelete.id ?
                                            `Are you sure you want to deregister ${toDelete.firstname} ${toDelete.lastname} from this subject?` :
                                            `Are you sure you want to deregister ${selectedStudents.length} students from this subject?`
                                    }
                                />
                                <div>
                                    <DataTable
                                        title={
                                            <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                                <span>{totalStudents} Enrolled Students</span>
                                                <button
                                                    className='btn btn-sm btn-danger rounded-0'
                                                    disabled={selectedStudents.length < 1}
                                                    onClick={() => {
                                                        setToDelete({})
                                                        setShowDialog(true)
                                                    }}
                                                >
                                                    <span className="me-2">Delete Selected</span>
                                                    <i className='bi bi-trash'></i>
                                                </button>
                                            </div>
                                        }
                                        columns={enrolled_students_columns}
                                        data={enrolledStudents}
                                        progressPending={isLoading}
                                        progressComponent={
                                            <div className="text-center">
                                                Loading... <Loading />
                                            </div>
                                        }
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalStudents}
                                        paginationPerPage={studentPerPage}
                                        onChangePage={page => setStudentCurrentPage(page)}
                                        onChangeRowsPerPage={perPage => setStudentPerPage(perPage)}
                                        paginationRowsPerPageOptions={[10, 30, 50, 150]}
                                        noDataComponent={"No students enrolled."}
                                    />
                                </div>
                            </div>
                        }
                    />
                </>
            }
        </div>
    )
}

export default ViewSubject
