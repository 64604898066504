import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { BASE_API_URL } from '../../utils/constants'

function Takwimu() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")
    const [isEditing, setIsEditing] = useState(false)

    const [details, setDetails] = useState({})

    const fetchSchoolDetails = async () => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools/infrastructure`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setDetails(data.details)
            }
        }
        catch (error) {
            setMessage("Error fetching school details. Please try again.")
            setVariant("danger")
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_API_URL}/schools/infrastructure`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth.token
                },
                body: JSON.stringify(details)
            })

            const data = await response.json()
            if (!response.ok) {
                if (response.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('warning')
                }
            }
            else {
                setIsEditing(false)
                setMessage(data.message)
                setVariant('success')
            }
        }
        catch (error) {
            setMessage("Failed to save facilities details. Please try again.")
            setVariant("warning")
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchSchoolDetails()
    }, [])

    return (
        <div className='pt-2'>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                    <p className='h3 fw-bold text-muted'>School Facilities</p>
                </div>
                <div>
                    <button
                        className='btn btn-primary btn-sm fw-bold rounded-0 me-2 mb-1 px-3'
                        onClick={() => setIsEditing(!isEditing)}
                    >
                        {
                            isEditing ? (
                                <>
                                    <span className="me-2">Cancel</span>
                                    <i className='bi bi-x-lg'></i>
                                </>
                            ) : (
                                <>
                                    <span className="me-2">Edit</span>
                                    <i className='bi bi-pencil-square'></i>
                                </>
                            )
                        }
                    </button>
                </div>
            </div>

            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3 shadow p-2">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        // this has to be the worst code you'll ever see!!!
                        // if you've seen or written anything worse than this...
                        // you probably dont deserve to look at this masterpiece, ha ha ha... ;)
                        <div className='mx-0'>
                            <form onSubmit={handleSubmit}>
                                <p className='h4 mb-3 text-muted'>Infrastructure Details</p>
                                <div className='row mb-3'>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='student_desks'>
                                                Student Desks <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='student_desks'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.student_desks}
                                                onChange={(e) => setDetails({ ...details, student_desks: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='student_chairs'>
                                                Student Chairs <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='student_chairs'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.student_chairs}
                                                onChange={(e) => setDetails({ ...details, student_chairs: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='student_tables'>
                                                Student Tables <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='student_tables'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.student_tables}
                                                onChange={(e) => setDetails({ ...details, student_tables: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='lab_stools'>
                                                Lab Stools <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='lab_stools'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.lab_stools}
                                                onChange={(e) => setDetails({ ...details, lab_stools: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='teacher_chairs'>
                                                Teacher Chairs <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='teacher_chairs'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.teacher_chairs}
                                                onChange={(e) => setDetails({ ...details, teacher_chairs: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='teacher_tables'>
                                                Teacher Tables <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='teacher_tables'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.teacher_tables}
                                                onChange={(e) => setDetails({ ...details, teacher_tables: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='kasiki'>
                                                Kasiki <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='kasiki'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.kasiki}
                                                onChange={(e) => setDetails({ ...details, kasiki: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='shubaka'>
                                                Shubaka <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='shubaka'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.shubaka}
                                                onChange={(e) => setDetails({ ...details, shubaka: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='kabati'>
                                                Kabati <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='kabati'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.kabati}
                                                onChange={(e) => setDetails({ ...details, kabati: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='benchi'>
                                                Benchi <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='benchi'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.benchi}
                                                onChange={(e) => setDetails({ ...details, benchi: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='vitanda'>
                                                Vitanda <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='vitanda'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.vitanda}
                                                onChange={(e) => setDetails({ ...details, vitanda: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='magodoro'>
                                                Magodoro <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='magodoro'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.magodoro}
                                                onChange={(e) => setDetails({ ...details, magodoro: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='computers'>
                                                Computers <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='computers'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.computers}
                                                onChange={(e) => setDetails({ ...details, computers: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='printers'>
                                                Printers <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='printers'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.printers}
                                                onChange={(e) => setDetails({ ...details, printers: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='photocopy_machines'>
                                                Photocopy Machines <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='photocopy_machines'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.photocopy_machines}
                                                onChange={(e) => setDetails({ ...details, photocopy_machines: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='vitabu'>
                                                Vitabu <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='vitabu'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.vitabu}
                                                onChange={(e) => setDetails({ ...details, vitabu: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='adminstrative_blocks'>
                                                Adminstrative Blocks <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='adminstrative_blocks'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.adminstrative_blocks}
                                                onChange={(e) => setDetails({ ...details, adminstrative_blocks: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='teacher_houses'>
                                                Teacher Houses <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='teacher_houses'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.teacher_houses}
                                                onChange={(e) => setDetails({ ...details, teacher_houses: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='stoo'>
                                                Stoo <strong className='text-danger ms-1'>*</strong>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='stoo'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.stoo}
                                                onChange={(e) => setDetails({ ...details, stoo: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='toilets_male_students'>
                                                Male Students Toilets
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='toilets_male_students'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.toilets_male_students}
                                                onChange={(e) => setDetails({ ...details, toilets_male_students: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='toilets_female_students'>
                                                Female Students Toilets
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='toilets_female_students'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.toilets_female_students}
                                                onChange={(e) => setDetails({ ...details, toilets_female_students: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='toilets_male_teachers'>
                                                Male Teachers Toilets
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='toilets_male_teachers'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.toilets_male_teachers}
                                                onChange={(e) => setDetails({ ...details, toilets_male_teachers: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='toilets_female_teachers'>
                                                Female Teachers Toilets
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='toilets_female_teachers'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.toilets_female_teachers}
                                                onChange={(e) => setDetails({ ...details, toilets_female_teachers: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='bwalo'>
                                                Bwalo
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='bwalo'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.bwalo}
                                                onChange={(e) => setDetails({ ...details, bwalo: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='bweni'>
                                                Bweni
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='bweni'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.bweni}
                                                onChange={(e) => setDetails({ ...details, bweni: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='water_tanks'>
                                                Water Tanks
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='water_tanks'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.water_tanks}
                                                onChange={(e) => setDetails({ ...details, water_tanks: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='libraries'>
                                                Libraries
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='libraries'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.libraries}
                                                onChange={(e) => setDetails({ ...details, libraries: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='kitchens'>
                                                Kitchens
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='kitchens'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.kitchens}
                                                onChange={(e) => setDetails({ ...details, kitchens: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 mb-3'>
                                        <div className='form-group'>
                                            <label htmlFor='classes'>
                                                Classes
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control rounded-0'
                                                id='classes'
                                                placeholder='0'
                                                required
                                                disabled={isLoading || !isEditing}
                                                value={details.classes}
                                                onChange={(e) => setDetails({ ...details, classes: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <p className='h4 mb-3 text-muted'>Academic Details</p>

                                {
                                    message &&
                                    <div className={`alert alert-${variant} p-2`} role="alert">
                                        {message}
                                    </div>
                                }

                                {
                                    isEditing && (
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <span>
                                                <strong className='text-danger ms-1'>*</strong> indicates required fields
                                            </span>
                                            <button type='submit' className='btn btn-primary px-4 rounded-0' disabled={isLoading}>
                                                {
                                                    isLoading ?
                                                        <>Saving... <Loading /></> :
                                                        <>
                                                            <span className="me-2">
                                                                Save
                                                            </span>
                                                            <i className='bi bi-floppy2-fill'></i>
                                                        </>
                                                }
                                            </button>
                                        </div>
                                    )
                                }
                            </form>
                        </div>
                }
            </div>
        </div>
    )
}

export default Takwimu
