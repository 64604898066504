import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import LoginCountChart from '../../components/charts/LoginCountChart'

function Dashboard() {
	const auth = useAuth()
	const location = useLocation()
	const error = location.state ? location.state.message : ''

	const [isLoading, setIsLoading] = useState(true)
	const [message, setMessage] = useState('')
	const [variant, setVariant] = useState('')

	const [cards, setCards] = useState([])
	const [quickLinks, setQuickLinks] = useState([])
	const [loginCount, setLoginCount] = useState([])

	const fetchDashboard = async () => {
		setIsLoading(true)
		setMessage('')

		try {
			const resp = await fetch(`${BASE_API_URL}/dashboard`, {
				headers: {
					'x-access-token': auth.token
				}
			})

			const data = await resp.json()
			if (!resp.ok) {
				if (resp.status === 401) {
                    const status = await auth.refresh()
					return
                }
				else {
					setMessage("Failed to fetch dashboard data. Please, try again.")
					setVariant("danger")
					return
				}
			}

			setCards(data.cards)
			setQuickLinks(data.quick_links)
			setLoginCount(data.login_count)
		}
		catch (error) {
			setMessage("Something went wrong. Please, try again.")
			setVariant("danger")
		}
		finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchDashboard()
	}, [])

	return (
		<div className='pt-2'>
			<div className="shadow p-2 mb-4">
				<span className='fw-bold text-muted'>
					Welcome, {auth.user.firstname} {auth.user.lastname}
				</span>
			</div>

			{
				error &&
				<div className={`alert alert-info p-2 mb-3`} role="alert">
					{error}
				</div>
			}

			{
				isLoading ?
					(
						<div className="text-center">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					)
					:
					(
						<>
							{
								cards && cards.length > 0 && (
									<div className='row mx-0 mb-4'>
										{
											cards.map((card, index) => (
												<Link to={card.link} key={index} className="col-lg-3 col-12 mb-3 pe-2 text-dark">
													<div className={`bg-${card.color} shadow ps-2`}>
														<div className="bg-white p-2">
															<span className="h3 fw-bold">
																{card.value}
															</span>
															<div className="d-flex align-items-center justify-content-between">
																<span className="text-muted fs-5">{card.title}</span>
																<i className={`bi bi-${card.icon} text-${card.color} fs-2`}></i>
															</div>
														</div>
													</div>
												</Link>
											))
										}
									</div>
								)
							}

							{
								quickLinks && quickLinks.length > 0 && (
									<div className="shadow p-2 mb-4">
										<span className='fw-bold text-muted mb-3'>
											Quick Links
										</span>

										<div className="row mx-0 my-3">
											{
												quickLinks.map((link, index) => (
													<div key={index} className="col-lg-4 col-12 mb-3">
														<div className="card bg-primary text-white mb-3">
															<div className="card-body">
																<h5 className="card-title">{link.title}</h5>
																<p className="card-text">{link.description}</p>
																<a href={link.url} className="btn btn-light">Go to {link.title}</a>
															</div>
														</div>
													</div>
												))
											}
										</div>
									</div>
								)
							}

							{
								loginCount && loginCount.length > 0 && (
									<div className="shadow p-2 mb-4">
										<span className='fw-bold text-muted mb-3'>
											Login Count
										</span>
										<LoginCountChart data={loginCount} />
									</div>
								)
							}
						</>
					)
			}
		</div>
	)
}

export default Dashboard
