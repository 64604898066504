import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { BASE_API_URL } from '../../utils/constants'
import Loading from '../../components/loading/Loading'
import DataTable from 'react-data-table-component'

function StudentsCount() {
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [variant, setVariant] = useState('')

    const [studentsCount, setStudentsCount] = useState([])

    const columns = [
        {
            name: 'S/N',
            selector: (row, index) => index + 1,
            grow: 0,
        },
        {
            name: 'School',
            selector: row => row.school?.name || "Total",
            sortable: true,
            grow: 2,
        },
        {
            name: <div style={{ display: 'flex', flexDirection: 'column' }} className='text-center'>
                <span className='mb-2'>Form I</span>
                <div className='row'>
                    <div className="col">F</div>
                    <div className="col">M</div>
                    <div className="col">T</div>
                </div>
            </div>,
            cell: row => (
                <div className='row'>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.form_one.female}
                    </div>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.form_one.male}
                    </div>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.form_one.total}
                    </div>
                </div>
            ),
            grow: 3,
        },
        {
            name: <div style={{ display: 'flex', flexDirection: 'column' }} className='text-center'>
                <span className='mb-2'>Form II</span>
                <div className='row'>
                    <div className="col">F</div>
                    <div className="col">M</div>
                    <div className="col">T</div>
                </div>
            </div>,
            cell: row => (
                <div className='row'>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.form_two.female}
                    </div>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.form_two.male}
                    </div>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.form_two.total}
                    </div>
                </div>
            ),
            grow: 3,
        },
        {
            name: <div style={{ display: 'flex', flexDirection: 'column' }} className='text-center'>
                <span className='mb-2'>Form III</span>
                <div className='row'>
                    <div className="col">F</div>
                    <div className="col">M</div>
                    <div className="col">T</div>
                </div>
            </div>,
            cell: row => (
                <div className='row'>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.form_three.female}
                    </div>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.form_three.male}
                    </div>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.form_three.total}
                    </div>
                </div>
            ),
            grow: 3,
        },
        {
            name: <div style={{ display: 'flex', flexDirection: 'column' }} className='text-center'>
                <span className='mb-2'>Form IV</span>
                <div className='row'>
                    <div className="col">F</div>
                    <div className="col">M</div>
                    <div className="col">T</div>
                </div>
            </div>,
            cell: row => (
                <div className='row'>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.form_four.female}
                    </div>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.form_four.male}
                    </div>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.form_four.total}
                    </div>
                </div>
            ),
            grow: 3,
        },
        {
            name: <div style={{ display: 'flex', flexDirection: 'column' }} className='text-center'>
                <span className='mb-2'>Total</span>
                <div className='row'>
                    <div className="col">F</div>
                    <div className="col">M</div>
                    <div className="col">T</div>
                </div>
            </div>,
            cell: row => (
                <div className='row'>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.total.female}
                    </div>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.total.male}
                    </div>
                    <div className="col col-auto me-0 pe-0" style={{whiteSpace: "nowrap"}}>
                        {row.total.total}
                    </div>
                </div>
            ),
            grow: 3,
        }
    ]

    const fetchStudentCount = async () => {
        setIsLoading(true)
        setMessage('')

        try {
            const resp = await fetch(`${BASE_API_URL}/count/students`, {
                headers: {
                    'x-access-token': auth.token
                }
            })

            const data = await resp.json()
            if (!resp.ok) {
                if (resp.status === 401) {
                    const status = await auth.refresh()
                }
                else {
                    setMessage(data.message)
                    setVariant('danger')
                }
            }
            else {
                setStudentsCount(data.count)
            }
        }
        catch (error) {
            setMessage('Something went wrong. Please, try again.')
            setVariant('danger')
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchStudentCount()
    }, [])

    return (
        <div className='pt-2'>
            <p className='h3 fw-bold text-muted'>Students Count</p>
            {
                message &&
                <div className={`alert alert-${variant} p-2`} role="alert">
                    {message}
                </div>
            }

            <div className="my-3">
                {
                    isLoading ?
                        <div className="text-center">
                            Loading... <Loading />
                        </div>
                        :
                        <DataTable
                            columns={columns}
                            data={studentsCount}
                            highlightOnHover
                        />
                }
            </div>
        </div>
    )
}

export default StudentsCount
